import { LOCALE_ID, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingsModule } from "./app-routings.module";
import { NgHttpLoaderModule } from "ng-http-loader";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { appInitializer } from "./app-initializer";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from "@ngrx/effects";
import { effects } from "./core/store/effects";
import { reducers } from "./core/store/reducers";
import { AuthInterceptor } from "./interceptors/auth-interceptor";
import { HttpErrorInterceptor } from "./interceptors/http-error.interceptor";
import { environment } from "../environments/environment";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { registerLocaleData } from "@angular/common";
import localePl from '@angular/common/locales/pl';
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from "../utils/custom-date-adapter";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { SPINNER_BACKGROUND_COLOR, SPINNER_COLOR } from "./models/const/strings";
import { Platform } from "@angular/cdk/platform";
import {CredentialsInterceptor} from "./interceptors/credentials.interceptor";
registerLocaleData(localePl);

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingsModule,
        NgHttpLoaderModule.forRoot(),
        BrowserModule,
        HttpClientModule,
        MatNativeDateModule,
        MatDatepickerModule,
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot(effects),
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            }
        }),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.chasingDots,
            backdropBackgroundColour: SPINNER_BACKGROUND_COLOR,
            primaryColour: SPINNER_COLOR,
            secondaryColour: SPINNER_COLOR,

        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        appInitializer,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CredentialsInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'pl-PL' },
        { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
        {
            provide: DateAdapter, useClass: CustomDateAdapter,  deps: [MAT_DATE_LOCALE, Platform]

        },
        provideAnimationsAsync()
    ],
})
export class AppModule {
}
