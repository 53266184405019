import {HttpErrorResponse} from '@angular/common/http';
import createHttpError from 'http-errors';
import {Injectable} from '@angular/core';
import {GLOBAL_MESSAGES} from "../models/const/strings";
import {ErrorModel} from "../models/interfaces/error-model";

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
    constructor() {
        (Error as unknown as { captureStackTrace: () => void }).captureStackTrace ??= () => {
            undefined;
        };
    }

    private async parseHttpResponse(response: HttpErrorResponse): Promise<ErrorModel | Error | createHttpError.HttpError> {
        if (response.error) {
            return response.error as ErrorModel;
        }

        console.error(response);
        const error = new Error();
        error.message = GLOBAL_MESSAGES.ERROR_HTTP;

        return error;
    }

    async generateError(response: HttpErrorResponse): Promise<Error | ErrorModel> {
        return await this.parseHttpResponse(response);
    }
}
