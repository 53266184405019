import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EventsActions } from "../actions";
import { EMPTY, of, switchMap } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { CALENDAR_EVENTS_INITIAL_DATE_FROM, CALENDAR_EVENTS_INITIAL_DATE_TO } from "../../../../utils/consts/const-dates";
import { EventsService } from "../../../services/events/events.service";
import { EventsFacade } from "../facade/events.facade";
import { ErrorFacade } from "../facade/error.facade";
import { Builder } from "../../../../utils/builders/builder";
import { EventsPagingMetaData } from "../../../models/interfaces/events-model";

@Injectable()
export class EventsEffects {
    readonly initEventsLoadingWithPaginationFromDate$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.initEventsLoadingWithPaginationFromDate),
        switchMap((action) => {
            const { dateFrom } = action;
            this.eventsService.eventsDateFromListBoundary = dateFrom;
            this.eventsService.eventsDateToListBoundary = dateFrom;
            return this.eventsService.getEvents(dateFrom, undefined,  1).pipe(
                mergeMap((eventsData) => [
                    EventsActions.setEvents({ eventsData: eventsData.data }),
                    EventsActions.setEventsPagingMetadata({ metaData: eventsData.metadata! }),
                ]),
                catchError(error => {
                    this.eventsService.isEventsLoading = false;
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly initSidebarCalendarEvents$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.initSidebarCalendarEvents),
        switchMap(() => {
            return this.eventsService.getCalendarEvents(CALENDAR_EVENTS_INITIAL_DATE_FROM, CALENDAR_EVENTS_INITIAL_DATE_TO).pipe(
                map(eventsData =>
                    EventsActions.addSidebarCalendarEvent({ eventsData: eventsData.data }),
                ),
                catchError(error => {
                    this.errorFacade.onShowError(error);
                    return of(EventsActions.addSidebarCalendarEvent({ eventsData: [] }));
                })
            );
        })
    ));

    readonly loadAndAddEventsChunk$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.loadAndAddEventsDataChunk),
        withLatestFrom(this.eventFacade.eventsList$),
        switchMap(([action, existingEvents]) => {
            const { dateFrom, dateTo, page } = action;
            return this.eventsService.getEvents(dateFrom, dateTo, page).pipe(
                switchMap(eventsApiResponse => {
                    const combinedEvents = [...existingEvents!, ...eventsApiResponse.data];
                    return [
                        EventsActions.setEvents({ eventsData: combinedEvents }),
                        EventsActions.setEventsPagingMetadata({ metaData: eventsApiResponse.metadata! }),
                    ];
                }),
                catchError(error => {
                    this.eventsService.isEventsLoading = false;
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly loadAndOverrideAllEventsData$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.loadAndOverrideAllEventsData),
        switchMap((action) => {
            const { dateFrom, dateTo, isPaginated } = action;
            return this.eventsService.getEvents(dateFrom, dateTo ? dateTo : undefined, dateTo ? undefined : 1).pipe(
                mergeMap((eventsData) => [
                    EventsActions.setEvents({ eventsData: eventsData.data }),
                    EventsActions.setEventsPagingMetadata({ metaData: isPaginated ? eventsData.metadata! : Builder<EventsPagingMetaData>().isNextPage(false).build() })
                ]),
                catchError(error => {
                    this.eventsService.isEventsLoading = false;
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly loadSidebarCalendarEventsDataChunk$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.loadSidebarCalendarEventsDataChunk),
        switchMap((action) => {
            const { dateFrom, dateTo } = action;
            return this.eventsService.getCalendarEvents(dateFrom, dateTo).pipe(
                map(eventsData =>
                    EventsActions.addSidebarCalendarEvent({ eventsData: eventsData.data })
                ),
                catchError(error => {
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly loadCalendarEventsDataChunk$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActions.loadCalendarEventsDataChunk),
            switchMap((action) => {
                const { dateFrom, dateTo } = action;
                return this.eventsService.getCalendarEvents(dateFrom, dateTo).pipe(
                    map(eventsData => EventsActions.addCalendarEvent({ eventsData: eventsData.data })),
                    switchMap((data) => [
                        EventsActions.addCalendarEvent({ eventsData: data.eventsData }), // Add your calendar events
                        EventsActions.calendarEventsDataLoaded() // Dispatch the completion action
                    ]),
                    catchError(error => {
                        this.errorFacade.onShowError(error);
                        return EMPTY;
                    })
                );
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly eventsService: EventsService,
        private readonly eventFacade: EventsFacade,
        private readonly errorFacade: ErrorFacade,
    ) {
    }
}
