import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {UserFacade} from "../core/store/facade/user.facade";
import {filter, first, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {ANNOUNCEMENT_URL, USER_INFO_URL} from "../models/const/api";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly userFacade: UserFacade,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler)  {
        if (this.isLoginRequest(request)) {
            return next.handle(request);
        }

        return this.userFacade.user$.pipe(
            filter(Boolean),
            first(),
            switchMap(() => next.handle(request)),
            catchError((error) => this.handleError(error))
        );
    }

    private isLoginRequest(request: HttpRequest<unknown>): boolean {
        return request.method === 'GET' && (request.url.endsWith(USER_INFO_URL) || request.url.endsWith(ANNOUNCEMENT_URL));
    }

    private handleError(error: HttpErrorResponse): never {
        throw error;
    }
}
