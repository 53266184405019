import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {NewsActions} from "../actions";
import { EMPTY, of, switchMap } from "rxjs";
import { catchError, filter, map, withLatestFrom } from "rxjs/operators";
import { NewsService } from "../../../services/news/news.service";
import { ErrorFacade } from "../facade/error.facade";
import { NewsFacade } from "../facade/news.facade";

@Injectable({
    providedIn: 'root',
})
export class NewsEffects {
    readonly getNewsData$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActions.initNews),
        switchMap(() => this.newsService.getNews().pipe(
          map((newsApiResponse) => NewsActions.setNews({ newsData: newsApiResponse.data })),
          catchError((error) => {
            this.errorFacade.onShowError(error);
            return of(NewsActions.setNews({ newsData: [] }));
          })
        ))
      ));

    readonly getTeacherMessagesData$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActions.initNews),
        switchMap(() => this.newsService.getTeacherMessages()),
        catchError((error) => {
            this.errorFacade.onShowError(error);
            return EMPTY;
        }),
        map((newsApiResponse) => NewsActions.setTeacherMessages({messages: newsApiResponse.data})),
    ));

    readonly loadRecentNewsDetails$ = createEffect(() => this.actions$.pipe(
        ofType(NewsActions.loadRecentNews),
        withLatestFrom(
            this.newsFacade.newsList$
        ),
        filter(([_messages, newsList]) =>
            newsList!.length > 0 && !!newsList![0].slug!
        ),
        switchMap(([_messages, newsList]) =>
            this.newsService.getSpecificNews(newsList![0].slug).pipe(
                map((newsData) => NewsActions.setRecentNewsDetails({newsData})),
                catchError((error) => {
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            )
        )
    ));
    constructor(
        private readonly actions$: Actions,
        private readonly newsFacade: NewsFacade,
        private readonly newsService: NewsService,
        private readonly errorFacade: ErrorFacade,
    ) {
    }
}
