import { eventsFeatureKey, eventsReducer, EventsState } from "./events.reducer";
import { newsFeatureKey, newsReducer, NewsState } from "./news.reducer";
import {userFeatureKey, userReducer, UserState} from "./user.reducer";
import {ActionReducerMap} from "@ngrx/store";

export interface AppState {
    [userFeatureKey]: UserState,
    [newsFeatureKey]: NewsState,
    [eventsFeatureKey]: EventsState,
}

export const reducers: ActionReducerMap<AppState> = {
    [userFeatureKey]: userReducer,
    [newsFeatureKey]: newsReducer,
    [eventsFeatureKey]: eventsReducer
}
