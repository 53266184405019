import {APP_INITIALIZER} from '@angular/core';
import {firstValueFrom, of} from 'rxjs';
import {UrlTree} from '@angular/router';
import {AuthService} from "./services/auth/auth.service";

export function initializeApp(authService: AuthService): () => Promise<void | UrlTree> {
    return () => {
        authService.init();
        return firstValueFrom(of(undefined));
    };
}

export const appInitializer = {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [AuthService],
    multi: true,
};
