import {Component, OnInit} from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { SPINNER_BACKGROUND_COLOR, SPINNER_COLOR } from './models/const/strings';
import {environment} from "../environments/environment";
import {GoogleAnalyticsService} from "./services/google-analytics/google-analytics.service";

@Component({
    selector: 'my-gwo-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    title = 'Moje-GWO';
    readonly Spinkit = Spinkit;

    readonly SPINNER_COLOR = SPINNER_COLOR;

    readonly SPINNER_BACKGROUND_COLOR = SPINNER_BACKGROUND_COLOR;

    readonly urlsToFilterOut = [
        'api/users',
        'calendarEvents'
    ];

    constructor(
        private readonly googleAnalyticsService: GoogleAnalyticsService,
    ) {
    }

    ngOnInit(): void {
        if (environment.production) {
            this.googleAnalyticsService.initialize();
        }
    }
}
