import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {ERROR, MY_ACCESS_URL} from "./models/const/urls";

const routes: Routes = [
    {
        path: '',
        loadChildren: async () => (await import('./modules/layout/layout.module')).LayoutModule,
    },
    {
        path: '',
        redirectTo: MY_ACCESS_URL,
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: `${ERROR.PAGE_NOT_FOUND}`,
        pathMatch: 'full',
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingsModule {
}
