import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ErrorHandlerService} from "../services/error-handler.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly errorHandlerService: ErrorHandlerService
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError((error) => this.handleError(error)));
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        return from(this.errorHandlerService.generateError(error)).pipe(
            map((generatedError) => {
                throw generatedError;
            })
        );
    }
}
